<template>
  <div :style="{ position: 'relative' }">
    <Loading
      :active.sync="loading"
      :isFullPage="false"
      :z-index="500"
      :width="45"
      :height="45"
    ></Loading>
    <div class="indicator-main">
      <div
        :class="[
          // { 'p-col-2': !$isMobile() },
          { 'p-col-12': $isMobile() },
          'indicator',
          'p-mr-1',
          'p-mt-1',
        ]"
        v-for="(indicator, index) in streakIndicators"
        :key="index"
        @click="indicatorClick(index, indicator.title)"
        v-tooltip="indicator.tooltip != undefined ? indicator.tooltip : ''"
      >
        <span class="indicator-text">
          {{ indicator.title }}
          <span
            v-if="indicator.icon != undefined"
            :class="[indicator.icon]"
            :style="{
              color:
                indicator.iconColor != undefined ? indicator.iconColor : '',
            }"
          />
        </span>
        <span
          :style="{
            'background-color':
              indicator.color != undefined ? indicator.color : '',
            color:
              indicator.counterColor != undefined ? indicator.counterColor : '',
          }"
          :class="[
            'indicator-value',
            { 'indicator-green': indicator.counter == 0 },
            {
              'indicator-yellow':
                indicator.counter > 0 && indicator.counter <= 20,
            },
            { 'indicator-red': indicator.counter > 20 },
            // { 'p-col-12': $isMobile() },
          ]"
          >{{ indicator.counter }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "W4MstreakIndicators",
  components: {
    Loading,
  },
  props: ["indicators", "loader"],
  watch: {
    indicators() {
      this.streakIndicators = this.indicators;
    },
    loader() {
      this.loading = this.loader;
    },
  },
  data() {
    return {
      loading: false,
      streakIndicators: this.indicators,
    };
  },
  methods: {
    indicatorClick(indicatorIndex, indicatorName) {
      return this.$emit("W4MstreakIndicatorsClick", {
        indicatorIndex,
        indicatorName,
      });
    },
  },
};
</script>
<style>
.indicator-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.indicator:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.indicator-text {
  color: #333;
  margin: 0 1rem;
}

.indicator-value {
  background-color: #20d077;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-right: 0.1rem;
  font-size: 1.3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.indicator-green {
  background-color: #20d077;
}
.indicator-red {
  background-color: #ef6262;
}
.indicator-yellow {
  background-color: #f9c851;
}
</style>
<style scoped>
/* .vld-overlay {
  z-index: 500 !important;
} */
</style>
